import React, { FC, CSSProperties } from 'react'
import { FiLogOut } from "react-icons/fi"


type Props = {
    style?: CSSProperties,
    className?: string
    onClick?: () => void
}

export const JumpToPostIcon: FC<Props> = ( { onClick, className, style } ) =>
    <FiLogOut className={ className } style={ style } onClick={ () => onClick && onClick() } />
