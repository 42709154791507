import React, { FC, useState } from 'react'
import Styles from "../styles/Comment.module.scss"
import { CommentType, isImageComment, isVideoComment } from '../types/models/Comment'
import moment from "moment"
import clsx from "clsx"
import { CommentUserAvator } from './CommentUserAvator'
import { OutputAuthorType, UserType } from '../types/models/User'
import { ToComment } from './ToComment'
import Video from './Video'
import MyImage from "./ImageComponent";
import { VideoSelectActionType } from '../types/WebViewVideoAction'
import { VideoType } from '../types/models/Media/Video'
import { ReactionList } from './ReactionList'
import useLongPress from '../hooks/useLongPress'
import { useLocalSettings } from '../hooks/useLocalSettings'
import { useIsBigScreen } from '../hooks/useIsBigScreen'
import LinkIncludedText from './LinkIncludedText'
import { VideoThumbnail } from './VideoThumbnail'
import { VideoModal } from './VideoModal'
import { navigate } from 'gatsby'
import { JumpToPostIcon } from "../icons/JumpToPostIcon"


type Props = {
    comment: CommentType
    toCommentAuthor?: OutputAuthorType
    hasFile: boolean
    displayUserAvator: boolean
    showThumbnailInitially: boolean
    showVideo: () => void
}

export const OthersCommentSearchItem: FC<Props> = ( {
    comment, toCommentAuthor, hasFile, displayUserAvator,
    showThumbnailInitially,
    showVideo } ) => {

    const [ videoModalVisible, setVideoModalVisible ] = useState( false );
    const [ hideReactionList, setHideReactionList ] = useState( comment.reactions.length === 0 );
    const localSettings = useLocalSettings( {} )
    const isBigScreen = useIsBigScreen()

    const renderDateTime = () =>
        <div className={ clsx( Styles.date_container ) }>
            <span className={ Styles.date }>
                { `${ moment( comment.created_at ).format( 'Y/M/D/ H:mm' ) }` }
            </span>
        </div>

    return (
        <div className={ clsx( Styles.container_wrapper, Styles.container_wrapper_with_date ) }>
            { !isBigScreen &&
                renderDateTime() }
            <div className={ clsx( Styles.container, hasFile && Styles.file_comment_container ) }>
                { displayUserAvator &&
                    <p>
                        <span style={ {
                            display: "inline-block",
                            marginBottom: 2,
                            fontSize: ".7rem",
                            fontWeight: "bold",
                            wordBreak: "keep-all",
                            color: "rgba(0,0,0,.75)"
                        } }>
                            { comment.output_author.name }
                        </span>
                    </p> }
                <div style={ { display: "flex" } }>
                    { displayUserAvator &&
                        <CommentUserAvator author={ comment.output_author } /> }
                    <div className={ clsx( Styles.comment_with_info ) }>
                        <div
                            id={ `comment-${ comment.pk }` }
                            className={ Styles.comment_main }>
                            { comment.to_comment_object && toCommentAuthor &&
                                <ToComment
                                    onMyComment={ false }
                                    toComment={ comment.to_comment_object }
                                    toCommentAuthor={ toCommentAuthor }
                                    scrollToComment={ () => { } } /> }
                            <div className={ Styles.file_and_text }>
                                { isVideoComment( comment ) &&
                                    <div className={ Styles.file_wrapper }>
                                        <VideoThumbnail
                                            onClick={ () => setVideoModalVisible( true ) }
                                            isSelected={ false }
                                            thumbnail={ comment.thumbnail }
                                            file={ comment.file }
                                            videoDuration={ comment.video_duration } />
                                        <VideoModal
                                            modalVisible={ videoModalVisible }
                                            setModalVisible={ setVideoModalVisible }
                                            file={ comment.file }
                                            thumbnail={ comment.thumbnail }
                                            handleVideoSelectSwitch={ () => { } }
                                            videoIsSelected={ false } />
                                    </div> }
                                { isImageComment( comment ) &&
                                    <div className={ Styles.file_wrapper }>
                                        <MyImage
                                            file={ comment.file } />
                                    </div> }
                                <p className={ Styles.comment_text }>
                                    <LinkIncludedText
                                        teamMemberList={ [] }>
                                        { comment.text }
                                    </LinkIncludedText>
                                </p>
                            </div>
                            { isBigScreen &&
                                renderDateTime() }
                        </div>
                        <div className={ clsx( Styles.jump_to_post_icon_container, Styles.hidden_jump_to_post_icon_container ) }>
                            <div
                                onClick={ () => navigate( `/post/${ comment.post_pk }?comment=${ comment.pk }` ) }
                                className={ Styles.jump_to_post_icon_wrapper }>
                                <span className={ Styles.jump_to_post_text }>この投稿に飛ぶ</span>
                                <JumpToPostIcon
                                    className={ Styles.jump_to_post_icon } />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
