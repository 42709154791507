import React, { FC, useState } from 'react'
import Styles from "../styles/MyComment.module.scss"
import { CommentType, isImageComment, isVideoComment } from '../types/models/Comment'
import moment from "moment"
import clsx from "clsx"
import { OutputAuthorType, UserType } from '../types/models/User'
import { ToComment } from './ToComment'
import MyImage from "./ImageComponent";
import { VideoSelectActionType } from '../types/WebViewVideoAction'
import { VideoType } from '../types/models/Media/Video'
import { ReactionList } from './ReactionList'
import useLongPress from '../hooks/useLongPress'
import { useLocalSettings } from '../hooks/useLocalSettings'
import { useIsBigScreen } from '../hooks/useIsBigScreen'
import LinkIncludedText from './LinkIncludedText'
import { VideoModal } from './VideoModal'
import { VideoThumbnail } from './VideoThumbnail'
import { navigate } from 'gatsby'
import { JumpToPostIcon } from "../icons/JumpToPostIcon"

type Props = {
    comment: CommentType
    toCommentAuthor?: OutputAuthorType
    hasFile: boolean
    showThumbnailInitially: boolean
    showVideo: () => void
}

export const MyCommentSearchItem: FC<Props> = ( { comment, toCommentAuthor, hasFile,
    showThumbnailInitially,
    showVideo, } ) => {

    const [ hideReactionList, setHideReactionList ] = useState( comment.reactions.length === 0 );
    const [ videoModalVisible, setVideoModalVisible ] = useState( false );
    const localSettings = useLocalSettings( {} )
    const isBigScreen = useIsBigScreen()

    const renderDateTime = () =>
        <div className={ Styles.date_container }>
            <span className={ Styles.date }>
                { `${ moment( comment.created_at ).format( 'Y/M/D/ H:mm' ) }` }
            </span>
        </div>

    return (
        <div className={ Styles.container_wrapper } style={ { marginTop: 16 } }>
            { !isBigScreen &&
                renderDateTime()
            }
            <div
                className={ clsx( Styles.container, hasFile && Styles.file_comment_container ) }>
                <div className={ Styles.comment_with_info }>
                    <div
                        id={ `comment-${ comment.pk }` }
                        className={ Styles.comment_main }>
                        { comment.to_comment_object && toCommentAuthor &&
                            <ToComment
                                onMyComment={ true }
                                toComment={ comment.to_comment_object }
                                toCommentAuthor={ toCommentAuthor }
                                scrollToComment={ () => { } } /> }
                        <div className={ Styles.file_and_text }>
                            { isVideoComment( comment ) &&
                                <div className={ Styles.file_wrapper }>
                                    <VideoThumbnail
                                        onClick={ () => setVideoModalVisible( true ) }
                                        isSelected={ false }
                                        thumbnail={ comment.thumbnail }
                                        videoDuration={ comment.video_duration }
                                        file={ comment.file } />
                                    <VideoModal
                                        modalVisible={ videoModalVisible }
                                        setModalVisible={ setVideoModalVisible }
                                        file={ comment.file }
                                        thumbnail={ comment.thumbnail }
                                        handleVideoSelectSwitch={ () => { } }
                                        videoIsSelected={ false } />
                                </div> }
                            { isImageComment( comment ) &&
                                <div className={ Styles.file_wrapper }>
                                    <MyImage
                                        file={ comment.file } />
                                </div> }
                            <p className={ Styles.comment_text }>
                                <LinkIncludedText
                                    teamMemberList={ [] }
                                    textStyle={ { color: "#fff" } }>
                                    { comment.text }
                                </LinkIncludedText></p>
                        </div>
                        { isBigScreen && renderDateTime() }
                    </div>
                    <div className={ clsx( Styles.jump_to_post_icon_container, Styles.hidden_jump_to_post_icon_container ) }>
                        <div
                            onClick={ () => navigate( `/post/${ comment.post_pk }?comment=${ comment.pk }` ) }
                            className={ Styles.jump_to_post_icon_wrapper }>
                            <span className={ Styles.jump_to_post_text }>この投稿に飛ぶ</span>
                            <JumpToPostIcon
                                className={ Styles.jump_to_post_icon } />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
