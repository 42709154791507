import React, { useState, useRef, useContext, FC } from "react"
import Styles from "../styles/CommentItem.module.scss";
import ActionSheet from "./ActionSheet";
import UserAvatar from "./UserAvatar";
import Modal from "./Modal";
import useDetectDevice from "../hooks/useDetectDevice";
import smoothscroll from 'smoothscroll-polyfill';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { navigate } from "gatsby";
import { ToastContext } from '../context/ToastContext';
import { CommentType, ArrangedCommentType, ReplyTargetType, ToCommentObjectType } from "../types/models/Comment";
import { UserType } from "../types/models/User";
import { ActionSheetMenuType } from "../types/models/ActionSheetMenu";
import { VideoSelectActionType } from "../types/WebViewVideoAction";
import { VideoType } from "../types/models/Media/Video";
import client from "../apis/client";
import { OthersCommentSearchItem } from "./OthersCommentSearchItem";
import { MyCommentSearchItem } from "./MyCommentSearchItem";
import { detectDevice } from "../functions/detectDevice";



if ( ( typeof window !== 'undefined' ) && ( typeof document !== 'undefined' ) ) {
    smoothscroll.polyfill();
}

type Props = {
    comment: Omit<ArrangedCommentType, 'sameAuthorAsPreviousComment'>,
    commentList: CommentType[],
}

const CommentSearchItem: FC<Props> = ( {
    comment,
    commentList,
} ) => {

    const { addToast } = useContext( ToastContext );
    const [ imageModalVisible, setImageModalVisible ] = useState( false )
    const [ showThumbnailInitially, setShowThumbnailInitially ] = useState( true )
    const CopyToClipboardRef = useRef<HTMLSpanElement>( null )

    // const ImageDownloadRef = useRef(null)

    const { isPC } = useDetectDevice()

    const scrollToComment = ( {
        commentPk,
        blink = false
    }: { commentPk: string, blink?: boolean } ) => {
        const toCommentObject = document.getElementById( `comment-${ commentPk }` )
        const toCommentY = toCommentObject ?
            toCommentObject.getBoundingClientRect().top :
            0
        const toCommentHeight = toCommentObject ?
            toCommentObject.getBoundingClientRect().height :
            0
        const doc = document.getElementsByTagName( "body" )[ 0 ]
        const currentScroll = window.pageYOffset
        const { isAndroid } = detectDevice()
        const scrollObject = isAndroid ?
            // doc :
            window :
            window
        const scrollAmount = isAndroid ?
            currentScroll + toCommentY - doc.getBoundingClientRect().height / 2 + toCommentHeight / 2 :
            // currentScroll + toCommentY - window.innerHeight / 2 + toCommentHeight / 2:
            currentScroll + toCommentY - window.innerHeight / 2 + toCommentHeight / 2
        scrollObject.scrollTo( {
            top: scrollAmount,
            behavior: "smooth"
        } );
        if ( !blink ) return
        toCommentObject && toCommentObject.classList.add( "blink" )
        setTimeout( () => {
            toCommentObject && toCommentObject.classList.remove( "blink" )
        }, 3000 )
    }


    const showImage = () => {
        setImageModalVisible( true )
    }

    const showVideo = () => {
        scrollToComment( { commentPk: comment.pk } )
        setShowThumbnailInitially( false )
    }


    const renderImageModal = () =>
        <Modal visible={ true } closeModal={ () => setImageModalVisible( false ) }>
            <>
                <p style={ { color: "#fff", textAlign: "center", marginBottom: 8 } }>
                    { isPC ? "右クリックメニューから保存できます" : "長押しで保存できます" }
                </p>
                <img src={ comment.file } style={ { display: "block", margin: "auto", maxWidth: "100%", maxHeight: "70vh" } } />
            </>
        </Modal>

    const renderModalAndActionSheet = () =>
        <>
            <CopyToClipboard
                text={ comment.text }
                onCopy={ () => addToast( { text: "文章をコピーしました", type: "success", position: 'center', } ) }>
                <span style={ { display: "none" } } ref={ CopyToClipboardRef }>クリップボード</span>
            </CopyToClipboard>
            {/* { renderActionSheet() } */ }
            { imageModalVisible && renderImageModal() }
        </>

    const fileComment = [ 1, 2 ].includes( comment.file_type )
    const displayUserAvatar = !( comment.deleted || comment.is_mine )
    const toCommentAuthor = commentList.filter( com => com.pk === comment.to_comment_object?.pk )[ 0 ]?.output_author


    if ( comment.deleted ) return null
    return (
        comment.is_mine ?
            <>
                <MyCommentSearchItem
                    comment={ comment }
                    hasFile={ fileComment }
                    toCommentAuthor={ toCommentAuthor }
                    showThumbnailInitially={ showThumbnailInitially }
                    showVideo={ () => showVideo() } />
                { renderModalAndActionSheet() }
            </> :
            <>
                <OthersCommentSearchItem
                    comment={ comment }
                    hasFile={ fileComment }
                    displayUserAvator={ displayUserAvatar }
                    toCommentAuthor={ toCommentAuthor }
                    showThumbnailInitially={ showThumbnailInitially }
                    showVideo={ () => showVideo() } />
                { renderModalAndActionSheet() }
            </>
    )
}

export default CommentSearchItem
